* {
  padding: 0%;
  margin: 0;
  box-sizing: border-box;
}
.pourcentage-diagnostique {
  transition: width 0.4s ease-in-out;
}

*::-webkit-scrollbar {
  display: none;
}

input:required:invalid {
  border-color: #c00000;
}

.App-header {
  background-color: #fff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgba(0, 0, 0, 0.7);
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 70px;
  height: 80px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid gray;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  4.9% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  5% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}
