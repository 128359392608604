/* **** */

/* :root {
    --primary-bg-color: #027AFC;
    --second-bg-color: #027AFC;
} */

:root {
  --primary-bg-color: #003b7f;
  --second-bg-color: #027afc;
}

.row {
  display: flex;
  align-items: center;
}

.center {
  height: 80vh;
  height: 80dvh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.btnFloat {
  position: fixed;
  bottom: 55px;
  right: 10px;
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  align-items: center;
  border-radius: 20px;
  background-color: green;
  color: #fff;
  padding: 7px 15px;
}

/* input:required:invalid {
    border-color: #c00000;
} */

/* Drawer */
.drawerContainer {
  height: 100vh;
  height: 100dvh;
  width: 100vw;
  background-color: #fff;
  position: fixed;
  z-index: 999;
  padding: 10px;
  top: 0%;
  transition: right 0.2555s ease-in-out;
}

/* height header */
.headerContainer {
  position: fixed;
  top: 0;
  left: 0;
  padding: 0 10px;
  background-color: var(--primary-bg-color);
  color: #fff;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.1); */
  z-index: 200;
}

.pageHeader {
  width: 100%;
  height: 50px;
  background-color: var(--primary-bg-color);
  align-items: center;
  display: flex;
  position: sticky;
  top: 0%;
  padding: 0 10px;
  color: #fff;
  z-index: 100;
}

/* Bottom tabs */
.bottomTabContainer {
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: #fff;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
}

.tabContainer {
  background-color: #fff;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  /* width: calc(100% / 4); */
  flex: 1;
  cursor: pointer;
  color: var(--primary-bg-color);
  text-decoration: none;
}

.tabContainerActive {
  background-color: var(--primary-bg-color);
  color: #fff;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  /* width: calc(100% / 4); */
  flex: 1;
  cursor: pointer;
  text-decoration: none;
}

/* .tabContainer:hover {
    background-color: #027AFC;
    color: #fff;
} */

/* Appel */
.appelContainer {
  position: fixed;
  width: 100%;
  height: 100vh;
  height: 100dvh;
  background-color: #fff;
  /* margin-top: 100px;    */
  overflow: hidden;
  /* background-color: yellow; */
  color: black;
  top: 50px;
}

.ficheContent {
  position: fixed;
  width: 100%;
  height: 100vh;
  height: 100dvh;
  background-color: #f5f5f5b8;
  overflow: hidden;
  color: black;
}

.appelTab {
  position: fixed;
  left: 0%;
  top: 50px;
  background-color: var(--primary-bg-color);
  height: 50px;
  width: 100%;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.GuideTab {
  position: fixed;
  left: 0%;
  top: 100px;
  background-color: var(--primary-bg-color);
  height: 50px;
  width: 100%;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.appelContent {
  position: fixed;
  left: 0%;
  top: 100px;
  width: 100%;
  height: 100%;
}

.evaluationContainer {
  background-color: rgba(236, 236, 236, 0.491);
}

/* Student card */
.studentCardContainer {
  width: 100%;
  height: auto;
  border-radius: 5px;
  background-color: #fff;
  padding: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(0, 0, 0, 0.3);
}

.studentCardEcole {
  position: absolute;
  left: 0;
  top: 0;
  border-top-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: var(--primary-bg-color);
  color: #fff;
  padding: 5px;
}

.studentCardAvatar {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background-color: #fff;
  margin-top: 50px;
  overflow: hidden;
}

.studentOverlay {
  position: absolute;
  bottom: 0%;
  right: 0%;
  width: 100%;
  height: 100%;
  background: rgb(2, 0, 36);
  background: linear-gradient(
    130deg,
    rgba(0, 0, 0, 0) 15%,
    rgba(0, 0, 0, 1) 100%
  );
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: flex-end;
  color: #fff;
  padding: 10px 10px;
  animation: studentOverlayAnimation 0.255s ease-in-out;
}

@keyframes studentOverlayAnimation {
  from {
    width: 40%;
    height: 40%;
  }

  to {
    width: 100%;
    height: 100%;
  }
}

.studentOverlayIcon {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: green;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.hidden {
  display: none;
}

/* Badge */
.badge {
  width: 17px;
  height: 17px;
  background-color: red;
  color: #fff;
  border-radius: 50%;
  padding: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -3px;
  right: -5px;
}

.headerPage {
  width: 100%;
  height: 50px;
  background-color: var(--primary-bg-color);
  position: sticky;
  top: 0%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.searchContainer {
  width: 100%;
  height: 27px;
  margin: 0 15px;
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.7);
  padding: 0 10px;
  align-items: center;
  display: flex;
  flex-direction: row;
}

.inputSearch {
  all: unset;
  width: 100%;
  padding: 0 10px;
  height: 100%;
}

.scrollViewContainer {
  height: 100%;
  width: 100%;
  position: absolute;
  overflow-y: auto;
  padding: 5px;
  padding-bottom: 180px;
}

.ficheContainer {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 10px;
  position: relative;
  background-color: #fff;
}

.fichesTitle {
  /* position: absolute; */
  /* top: 0%;
    left: 0%; */
  border-radius: 5px;
  padding: 4px 5px;
  background-color: var(--primary-bg-color);
  color: #fff;
  margin-bottom: 10px;
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--primary-bg-color);
  margin-right: 10px;
}

:root {
  --select-border: #777;
  --select-focus: blue;
  --select-arrow: var(--select-border);
}

/* Start Sidebar */
.sidebarHeader {
  height: 80px;
  width: 100%;
  padding: 0.5rem;
  background-color: rgba(241, 241, 241, 0.861);
  border-bottom: 1px solid rgb(217, 217, 217);
}

.flex {
  display: flex;
  align-items: center;
}

.sidebarAvatar {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: black;
  display: grid;
  place-items: center;
}

/* link */
.linkContainer {
  flex: 1;
  padding: 0.5rem;
  color: rgba(0, 0, 0, 0.7);
}

.linkItem {
  /* flex: 1; */
  border-radius: 5px;
  padding: 0.45rem;
  position: relative;
  cursor: pointer;
  margin-bottom: 8px;
  padding-left: 10px;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.7);
}
.linkItemActive {
  background-color: rgba(220, 220, 220, 0.431);
  border-radius: 5px;
  padding: 0.45rem;
  position: relative;
  cursor: pointer;
  margin-bottom: 8px;
  padding-left: 10px;
}

.linkItem:hover {
  background-color: rgba(220, 220, 220, 0.73);
}

.linkItemActive::after {
  content: "";
  position: absolute;
  left: 0%;
  top: 50%;
  transform: translateY(-50%);
  width: 3px;
  height: 70%;
  background-color: rgb(11, 14, 48);
  border-radius: 20px;
}

/* End Sidebar*/

/* Start Button */
.Button {
  all: unset;
  padding: 0.5rem 0.7rem;
  background-color: #428fdc;
  font-size: 14px;
  color: #fff;
  border-radius: 5px;
  font-weight: 600;
  cursor: pointer;
  border: 1px solid rgba(217, 217, 217, 0.707);
}

.Button:active:not(:disabled) {
  transform: translateY(1px);
}

.Button:focus {
  outline: 2.5px solid rgb(236, 236, 236);
}

.Button:hover {
  background-color: #2679cb;
}

.Button:disabled {
  opacity: 0.7;
  /* cursor: progress; */
  cursor: not-allowed;
}

.ButtonLoader {
  width: 15px;
  height: 15px;
  border: 2px solid #fff;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* End Button*/

/* Start ProjetPage */
.projetPageContainer {
  color: gray;
}

.tabPage {
  flex: 1;
  border-bottom: 1px solid rgba(217, 217, 217, 0.707);
  /* background-color: #2679cb; */
  min-height: 45px;
  display: flex;
  align-items: center;
  flex: 1;
  padding-right: 10px;
}

.tabPageItem {
  display: grid;
  place-items: center;
  height: 45px;
  padding: 0.3rem 1rem;
  font-weight: 500;
  font-size: 13.5px;
  cursor: pointer;
}

.tabPageItemActive {
  display: grid;
  place-items: center;
  height: 45px;
  padding: 0.3rem 1rem;
  font-weight: 500;
  font-size: 13.5px;
  border-bottom: 2.5px solid rgb(191, 191, 191);
  cursor: pointer;
}

.tabPageItem:hover {
  border-bottom: 2px solid rgb(191, 191, 191);
}

.tableRow {
  min-height: 70px;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 1rem;
  border-bottom: 1px solid rgba(217, 217, 217, 0.707);
}

.tableRow:hover {
  background-color: #f2f2f2a9;
}

.tableAvatar {
  width: 40px;
  height: 40px;
  border: 1px solid rgba(217, 217, 217, 0.707);
  border-radius: 5px;
  display: grid;
  place-items: center;
  font-size: 1.5rem;
  font-weight: 600;
}

/* End ProjetPage*/

/* Start  Input*/
.input {
  all: unset;
  border: 1px solid rgba(217, 217, 217, 0.707);
  border-radius: 5px;
  font-size: 14px;
  padding: 0.555rem 1rem;
  cursor: auto;
}

.input::placeholder {
  color: rgb(191, 191, 191);
}

.input:focus {
  outline: 3px solid rgb(236, 236, 236);
}

.inputSelect {
  all: unset;
  border: 1px solid rgba(217, 217, 217, 0.707);
  border-radius: 5px;
  font-size: 14px;
  padding: 0.555rem 1rem;
  cursor: auto;
}

.inputSelect::placeholder {
  color: rgb(191, 191, 191);
}

.inputSelect:focus {
  outline: 3px solid rgb(236, 236, 236);
}
/* End Input*/

/* Start Spinet  */

/* End Spinet */
/* Start  */
/* End */
/* Start  */
/* End */
/* Start  */
/* End */
/* Start  */
/* End */
/* Start  */
/* End */
/* Start  */
/* End */
/* Start  */
/* End */
/* Start  */
/* End */
